* {
  box-sizing: border-box;
  margin: 0;
}

html {
  height: 100%;
}

body {
  height    : 100vh;
  overflow  : auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#root {
  display             : grid;
  grid-template-rows  : auto 1fr auto;
  background          : url(../public/cloud.svg) no-repeat center/cover;
  place-content       : center;
  place-items         : center;
}

@media (max-width: 600px) {
  #root {
    background  : rgb(173, 216, 230);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
