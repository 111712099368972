div.app {
  display             : grid;
  grid-template-rows  : auto 1fr auto auto;
  align-items         : flex-start;
  row-gap             : 20px;
  min-height          : 100vh;

  @media (max-width: 600px) {
    width           : 100%;
    max-width       : 375px;
    padding         : unset;
    row-gap         : 0px;
  }
}