nav.Header {
  display               : grid;
  place-items           : center;
  padding               : 20px;

  img {
    width: 160px;
    transition      : .5s all;

    &:hover {
      transform     : scale(1.1);
    }
  }
}

@media (max-width: 600px) {
  nav.Header {
    max-width: 375px;
    padding: 20px;
  }
}