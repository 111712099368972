div.Footer {
  display               : grid;
  place-content         : center;
  place-items           : center;
  column-gap            : 50px;
  font-size             : 12px;
  color                 : black;

  & > a {
    text-decoration : none;
    transition      : .5s all;

    &:hover {
      transform     : scale(1.1);
    }
  }
}

@media (max-width: 600px) {
  div.Footer {
    max-width: 375px;
    grid-template-columns: unset;
    padding-bottom: 10px;
  }
}